import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import {
  getToken, getReToken, setToken, setReToken
} from '@/utils/auth'
// import Cookies from 'js-cookie'
// import { isRef } from 'vue'
// import { resolve } from 'core-js/fn/promise'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// const refreshAuthLogic = (failedRequest) => {
//   service.post('/retoken', {
//     refreshCode: Cookies.get('retoken')
//   }).then(tokenRefreshResponse => {
//     failedRequest.response.config.headers['token'] = tokenRefreshResponse.data.access_token

//     Cookies.set('Admin-Token', 'Bearer ' + tokenRefreshResponse.data.access_token)
//     return Promise.resolve()
//   }).catch(error => {
//     console.log(error.message)
//     return Promise.reject(error)
//   })
// }

// createAuthRefreshInterceptor(service, refreshAuthLogic, {
//   pauseInstanceWhileRefreshing: true,
//   skipAuthRefresh: true,
//   retryInstance: refreshAuthLogic,
//   interceptNetworkError: true,
//   statusCodes: [401, 402]
// })

// 刷新 access_token 的接口
const refreshToken = () => {
  console.log('刷新 access_token 的接口')
  return service.post('/retoken', { refreshCode: getReToken() }, true)
}

let isRefreshing = false // 标记是否正在刷新 token
let requests = [] // 存储待重发请求的数组

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      } else {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 10 * 1000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // console.log('重新获取code:'+error.response.status)
    if (!error.response) {
      return Promise.reject(error)
    }
    // && !error.config.url.includes('/retoken')
    if ((error.response.status === 401 || error.response.status === 402)) {
      // console.log('重新获取token  ')
      const { config } = error
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken().then(res => {
          const { access_token, refresh_token } = res.data
          // console.log(res.data)
          // console.log(`access_token ${access_token}`)
          setToken('Bearer ' + access_token)
          setReToken(refresh_token)

          config.headers.Authorization = 'Bearer ' + access_token
          // 刷新后将数组的方法重新执行
          // console.log('requestsLen'+requests.length)
          requests.forEach((cb) => {
            console.log('cb')
            cb(access_token)
          })
          requests = [] // 重新请求完成清空
          // console.log('requestsLen'+requests.length)
          return service(config)
        }).catch(err => {
          console.log('抱歉，您的登录状态已失效，请重新登录！')
          return Promise.reject(err)
        }).finally(() => {
          isRefreshing = false
        })
      } else {
        return new Promise(resolve => {
          requests.push(token => {
            config.headers.Authorization = 'Bearer ' + token
            resolve(service(config))
          })
        })
      }
    }
    return Promise.reject(error)
  })

export default service
