<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['level'])
  }
}
</script>
<template>
  <div class="member-level" style="display: none">
    <div
      class="item"
      :class="'level-'+level"
    >
      <svg-icon
        :icon-class="'member-level-'+level"
        class-name="svg-icon-size"
      />
      <span>星钻 SSVIP</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.member-level{
    display: inline-block;
    padding: 0 12px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;
  .item{
    margin-top: 12px;
    display: flex;
    align-items: center;
    background:#ccc;
    border-radius:100px;
    height: 30px;
    line-height: 30px !important;
    font-size: 14px !important;
    padding: 6px 10px;
    cursor: pointer;
    span{
      display: inline-block;
      margin-left: 5px;
    }
  }
  .level-1{
    color: #68400b;
    background:linear-gradient(255deg,#feb75b,#ffd77a);
  }
  .level-2{
    color: #8b452c;
     background-image: linear-gradient(90deg,#ffd8cb,#ffaf96);
  }
  .level-3{
    color: #66408d;
    background-image: linear-gradient(90deg,#e3d0ff,#d4b7ff);
  }
}
.svg-icon-size{
  font-size: 20px;
}
</style>
