export default {
  appname: 'Quotation',
  notfound: 'Page Not Found',
  backhome: 'Back to Home',
  about: 'About Us',
  nodata: 'No Data Available',
  loading: 'Loading...',
  browser: 'Browser',
  docs: 'Documentation',
  logout: 'Logout',
  solang:
    'Helping you start a more efficient, intelligent quoting journey! Make your quotes more attractive and enhance communication with customers.',
  success: 'Modification Successful',
  back: 'Back',
  menu: {
    home: 'Home',
    quotation: 'Quotation Management',
    company: 'Company Management',
    sms: 'SMS Service',
    setting: 'Personal Settings',
    createquotation: 'Add Quotation',
    previewquotation: 'Preview Quotation',
    createcompany: 'Add Company',
    companydetail: 'Company Details',
    buysms: 'Buy SMS',
    updatepassword: 'Change Password',
    joinvip: 'Become a Member',
    userevaluate: 'User Evaluation'
  },
  login: {
    nameplaceholder: "Enter your username",
    passwordplaceholder: "Enter your password",
    submit: "Login Now",
    register: "New User Registration",
    password_error: "Incorrect password",
    user_no_exist: "User does not exist"
  },
  dashboard: {
    welcome: 'Welcome to the Quotation Everyday Management System',
    instructions: 'Instructions',
    feedback: 'Feedback/Online Support',
    roadmap: 'Roadmap',
    changelog: 'Changelog',
    guide: 'Cloud Migration Guide',
    setp1: 'Set Company/Personal Information',
    setp2: 'Start Quoting',
    source: 'Traffic Source',
    arearank: 'Area Ranking',
    pv: 'Page Views',
    pv_7: 'Last 7 Days',
    pv_15: 'Last 15 Days',
    pv_30: 'Last 30 Days',
    unknown_area: 'Unknown Area',
    proportion: 'Percentage'
  },
  person: {
    basesetting: 'Basic Information',
    nickname: 'Nickname',
    nickname_placeholder: 'Enter Nickname',
    email: 'Email',
    email_placeholder: 'Enter Email',
    mobile: 'Contact Number',
    mobile_placeholder: 'Enter Contact Number',
    avatar: 'Avatar',
    upload_avatar: 'Upload Avatar',
    address: 'Contact Address',
    address_placeholder: 'Enter Contact Address',
    submit_text: 'Confirm Changes'
  },
  upload: {
    preview_title: 'Preview',
    upload_text: 'Upload Image',
    modal_title: 'Confirm Delete this Image?',
    modal_tips_text: 'Note',
    modal_confirm_btn: 'Confirm',
    modal_cancel_btn: 'Cancel',
    limit: 'Image limit exceeded, maximum {limit} images allowed',
    size: 'Image size should not exceed 3MB'
  },
  sms: {
    package_title: 'Package Balance',
    newuser: '3 free SMS for new users',
    package_tips: 'SMS Package Balance (messages)',
    buy_btn_text: 'Buy',
    recorde_title: 'Sending Records',
    unknown_user: 'Unknown User',
    table_nickname: 'Customer Nickname',
    table_phone: 'Customer Phone',
    table_status: 'Sending Status',
    table_time: 'Sending Time',
    table_status_success: 'Success',
    buy_package_title: 'Buy Package',
    buy_tips:
      'Online purchase is not available at the moment, please contact customer support for purchasing.'
  },
  company: {
    tips: 'Current version supports adding only 1 company',
    create: 'Add',
    table_title: 'Company Name',
    table_contact: 'Contact Person',
    table_mobile: 'Contact Number',
    table_time: 'Add Time',
    table_edit_btn: 'Edit',
    table_delete_btn: 'Delete',
    table_action: 'Action',
    modal_title: 'Confirm Delete this Company?',
    modal_tips_text: 'Note',
    modal_confim_btn: 'Confirm',
    modal_cancel_btn: 'Cancel',
    modal_delete_success: 'Deletion Successful',
    modal_delete_cancel: 'Deletion Canceled',
    form_company: 'Company Name',
    form_contact: 'Contact Person',
    form_mobile: 'Contact Number',
    form_address: 'Address',
    form_site: 'Website',
    form_wechat: 'WeChat',
    form_email: 'Email',
    form_qq: 'QQ',
    form_seal: 'Company Seal',
    form_seal_upload_tips: 'Upload Company Seal',
    form_valid_company: 'Enter Company Name',
    form_valid_contact: 'Enter Contact Person',
    form_valid_address: 'Enter Company Address',
    form_valid_mobile: 'Enter Mobile Number',
    form_cancel_btn: 'Cancel',
    form_comfig_btn: 'Save Changes',
    form_create_success: 'Addition Successful',
    form_update_success: 'Modification Successful',
    detail: 'Company Details'
  },
  offer: {
    filter: {
      date: 'Date Range',
      date_start: 'Start Date',
      date_end: 'End Date',
      date_to: 'to',
      customer: 'Customer',
      customer_placeholder: 'Enter Customer Name',
      search_btn: 'Search'
    },
    form: {
      create: 'Add Quotation',
      base_info: 'Basic Information',
      customer_name: 'Company Name',
      valid_at_placeholder: 'Select Date',
      customer_address: 'Company Address',
      customer_contact: 'Contact Person',
      customer_mobile: 'Mobile Number',
      customer_email: 'Company Email',
      date: 'Quotation Date',
      date_placeholer: 'Select Date',
      validate: 'Quotation Validity',
      goods: {
        card_title: 'Product Information',
        create_goods: 'Add Product',
        name: 'Name',
        model: 'Model',
        num: 'Quantity',
        unit_price: 'Unit Price',
        total_price: 'Total Price',
        remark: 'Remarks',
        action: 'Action',
        action_view: 'View',
        action_edit: 'Edit',
        action_delete: 'Remove',
        total_amount: 'Total'
      },
      extent: {
        select_contact: 'Select Contact Method',
        company_title: 'Company Name',
        compmay_placeholder: 'Select Company Name',
        seal: 'Company Seal',
        seal_tips: 'Will be displayed on the quotation',
        remark: 'Remarks',
        remark_placeholder: 'Enter Content'
      },
      createok: {
        title: 'Quotation Added Successfully',
        send_sms_notification: 'Send SMS Notification',
        back_offer_list: 'Back to Quotation List'
      },
      cancel_btn: 'Cancel',
      submit_btn: 'Submit',
      view_goods: 'View Products',
      good_rules: {
        name: 'Enter Product Name',
        type: 'Enter Product Model',
        num: 'Enter Product Quantity',
        unit: 'Select Unit',
        unit_price: 'Enter Unit Price',
        create_at: 'Select Quotation Date',
        company_id: 'Select Company Name',
        customer_name: 'Enter Company Name',
        customer_conatact: 'Enter Contact Person',
        customer_phone: 'Enter Mobile Number',
        customer_address: 'Enter Address',
        valid_at: 'Select Quotation Validity Date',
        company_name: 'Enter Company Name',
        company_address: 'Enter Company Address',
        company_contact: 'Enter Contact Person',
        company_mobile: 'Enter Mobile Number'
      },
      other: {
        title: 'Add Product',
        modal_text: 'Are you sure you want to delete this product?',
        modal_title: 'Note',
        modal_ok: 'Confirm',
        modal_cancel: 'Cancel',
        delete_success: 'Deletion Successful',
        create_success: 'Addition Successful',
        update_success: 'Modification Successful',
        sms_send_success: 'SMS Sent Successfully'
      },
      
    },
    table: {
      customer: 'Customer Information',
      validate: 'Quotation Validity',
      create_time: 'Quotation Date',
      pv: 'Page Views',
      share_status: {
        title: 'Share Status',
        options: ['Sharing', 'Not Shared']
      },
      sms_num: 'SMS Sent',
      action: {
        title: 'Action',
        share: 'Share',
        send_sms: 'Send SMS',
        detail: 'Details'
      }
    },
    sendsms: {
      no_mobile_error: 'Customer mobile not provided',
      modal_title:
        'Sending a message will consume one SMS credit. Are you sure?',
      modal_tips: 'Note',
      submit_btn: 'Confirm',
      cancel_btn: 'Cancel',
      send_success: 'Message Sent Successfully',
      send_faild: 'Failed to Send!',
      send_cancel: 'Cancel Sending'
    },
    pvdialog: {
      title: 'Page Views History',
      index: 'Index',
      address: 'Location',
      date: 'Viewing Date',
      ip: 'IP',
      browser: 'Browser'
    },
    sharedialog: {
      title: 'Share Settings',
      tips: 'To disable sharing: turn off the switch or change the viewing password',
      status: 'Share Status',
      link: 'View Link',
      password: 'Viewing Password',
      refresh: 'Refresh',
      btn: 'Copy Link and Password',
      open_share_success: 'Sharing Enabled Successfully',
      close_share_success: 'Sharing Disabled Successfully',
      updatepassword: 'Viewing Password Updated Successfully',
      copy: [
        'Link',
        'Viewing Password',
        'A website for creating free quotations'
      ]
    },
    detail: {
      title: 'Quotation Details',
      customer_name: 'Customer Name',
      customer_address: 'Company Address',
      customer_contact: 'Contact Person',
      customer_mobile: 'Contact Information',
      customer_email: 'Company Email',
      no: 'Quotation Number',
      date: 'Quotation Date',
      validate: 'Quotation Validity',
      goods_card_title: 'Product Information',
      goods: {
        index: 'Index',
        name: 'Product Name',
        model: 'Model and Specifications',
        num_unit: 'Quantity/Unit',
        unit_price: 'Unit Price',
        total_price: 'Total Price',
        remark: 'Remarks'
      },
      company_title: 'Quotation Unit',
      company_website: 'Website',
      company_contact: 'Contact Person',
      company_mobile: 'Contact Information',
      company_wechat: 'WeChat',
      company_seal: 'Company Seal',
      company_address: 'Address',
      company_email: 'Email',
      company_qq: 'QQ',
      total_price: 'Total',
      edit_btn: 'Edit',
      print_btn: 'Print Quotation',
      delete_btn: 'Delete',
      comfirm_delete: 'Confirm Deletion?',
      delete_success: 'Deletion Successful',
      customer_info: 'Customer Information',
      editgood:{
        name:'name',
        type:'type',
        num:'num',
        unit_price: 'Unit Price',
        unit: 'Unit',
        unit_placeholder: 'Please select a unit',
        images: 'Product Images',
        upload_images_text: 'Upload Product Images',
        video: 'Video',
        upload_video_text: 'Upload Video',
        remark: 'Remark',
        submit: 'Submit',
        cancel: 'Cancel',
        rules: 'Please enter',
        create_success: 'Added successfully',
        update_success: 'Modified successfully'
      },
    }
  },
  share: {
    view_password: 'Enter Viewing Password',
    view_btn: 'View Quotation',
    password_error:'Incorrect Password'
  },
  footer: {
    home: 'Home',
    info: 'Instructions',
    feedback: 'Feedback',
    roadmap: 'Roadmap'
  },
  register: {
    input_name: "Enter your username",
    input_password: "Enter your password",
    login: "Register Now",
    gologin: "Go to Login",
    success: "Registration successful",
    exist: "Username already exists"
  }
  
}
