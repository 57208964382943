import request from '@/utils/request'

export function register(data) {
  return request({
    url: 'register',
    method: 'post',
    data
  })
}
export function login(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

export function refreshToken(data) {
  return request({
    url: '/retoken',
    method: 'post',
    data
  })
}

export function getInfo(data) {
  return request({
    url: '/api/userinfo',
    method: 'post',
    data
  })
}

export function changePassword(data) {
  return request({
    url: '/api/changepassword',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: 'users/logout',
    method: 'post'
  })
}

// 修改用户基本信息
export function settingBaseinfo(data) {
  return request({
    url: 'api/setting/baseinfo',
    method: 'post',
    data
  })
}

// 获取用户基本信息
export function getBaseInfo() {
  return request({
    url: 'api/get/base/info', // 假地址 自行替换
    method: 'get'
  })
}

