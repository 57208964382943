<template>
  <div>
    <el-popover
      v-model="visible"
      placement="bottom-end"
      width="400"
      trigger="click"
    >
      <div v-if="gridData && gridData.length>0" class="message-box">
        <div v-for="(item,index) in gridData" :key="index" class="item">
          <svg t="1682226803614" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5229" width="40" height="40"><path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#F79484" p-id="5230" /><path d="M609.28 494.08L577.28 409.6c-28.16-72.96-101.12-117.76-176.64-106.24-12.8-17.92-35.84-25.6-56.32-16.64-21.76 7.68-33.28 30.72-29.44 52.48-57.6 43.52-79.36 122.88-52.48 192l30.72 78.08c-17.92 23.04-21.76 53.76-11.52 80.64v1.28c3.84 10.24 10.24 20.48 17.92 28.16h1.28c6.4 6.4 14.08 8.96 23.04 8.96 2.56 0 5.12-1.28 10.24-2.56l314.88-121.6c2.56-1.28 3.84-1.28 6.4-3.84 8.96-5.12 14.08-14.08 14.08-23.04 0-11.52-1.28-24.32-6.4-34.56v-1.28c-10.24-21.76-29.44-40.96-53.76-47.36z" fill="#FFFFFF" opacity=".5" p-id="5231" /><path d="M482.56 729.6c0 32 25.6 57.6 57.6 57.6s57.6-25.6 57.6-57.6h-115.2z m254.72-165.12v-102.4c0-89.6-58.88-165.12-143.36-184.32-6.4-24.32-28.16-40.96-52.48-40.96-25.6 0-47.36 19.2-52.48 43.52-79.36 23.04-134.4 98.56-134.4 181.76v96c-28.16 17.92-44.8 48.64-44.8 81.92v1.28c0 12.8 2.56 25.6 7.68 37.12l1.28 1.28c3.84 8.96 11.52 15.36 20.48 17.92 2.56 1.28 6.4 1.28 11.52 1.28h382.72c2.56 0 5.12 0 7.68-1.28 11.52-1.28 20.48-8.96 24.32-17.92 5.12-11.52 7.68-25.6 7.68-38.4V640c0-29.44-12.8-56.32-35.84-75.52z" fill="#FFFFFF" p-id="5232" /></svg>
          <div class="right">
            来自浙江的用户浏览了你的报价单
            <div class="date">{{ item.date }}</div>
          </div>
        </div>
        <div class="clear-button">
          <el-link type="default" :underline="false" icon="el-icon-delete" @click="handleClearMessage">清空通知</el-link>
        </div>
      </div>
      <empty v-else text="暂无通知" />
      <span slot="reference">

        <!-- <el-badge is-dot class="item" :value="40" hidden> -->
        <svg-icon class-name="message-icon" icon-class="message" />
        <!-- </el-badge> -->
      </span>

    </el-popover>

  </div>
</template>

<script>
import screenfull from 'screenfull'
import empty from '@/components/Empty'
export default {
  name: 'Screenfull',
  components: { empty },
  data() {
    return {
      isFullscreen: false,
      visible: false,
      gridData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }]
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    click() {
      if (!screenfull.enabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    handleClearMessage() {
      this.$message.success('清空成功')
      this.gridData = []
      this.visible = false
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.enabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.change)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-badge__content.is-fixed.is-dot {
    right: 5px;
    top: 10px;
}
.message-icon {
  display: inline-block;
  cursor: pointer;
  fill: #e63104;;
  width: 22px;
  height: 22px;
  vertical-align: -4px;
}
.message-box{
    .item{
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
        .right{
            margin-left: 15px;
            color: #333;
            font-weight: bold;
        }
        .date{
            color: #999;
            font-weight: normal;
            margin-top: 3px;
        }
    }
}
.clear-button{
    margin: 10px auto 0 auto;
    text-align: center;
}
</style>
