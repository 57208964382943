import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const refreshTokenKey = 'retoken'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getReToken() {
  return Cookies.get(refreshTokenKey)
}

export function setReToken(token) {
  return Cookies.set(refreshTokenKey, token)
}

export function removeReToken() {
  return Cookies.remove(refreshTokenKey)
}
