import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index')
  }]
},
{
  path: '/pdf',
  component: () => import('@/views/pdf/index'),
  hidden: false
},
{
  path: '/pdfcreate',
  component: () => import('@/views/pdf/create'),
  hidden: false
},
{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
},
{
  path:'/test',
  component: () => import('@/views/test/index'),
  hidden: true
},
{
  path: '/register',
  component: () => import('@/views/login/register'),
  hidden: true
},
{
  path: '/auth-redirect',
  component: () => import('@/views/login/auth-redirect'),
  hidden: true
},
{
  path: '/share/:id',
  hidden: true,
  name: 'share',
  component: () => import('@/views/viewDetail/index.vue')
},
{
  path: '/404',
  component: () => import('@/views/error-page/404'),
  hidden: true
},
{
  path: '/401',
  component: () => import('@/views/error-page/401'),
  hidden: true
},
{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: () => import('@/views/dashboard/index'),
    name: 'Dashboard',
    meta: {
      title: i18n.t('menu.home'),
      icon: 'el-icon-s-home',
      affix: true
    }
  }]
},

{
  path: '/about',
  component: Layout,
  redirect: '/about/index',
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/about/index'),
    name: 'Profile',
    meta: {
      title: i18n.t("about"),
      icon: 'user',
      noCache: true
    }
  }]
}
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [{
  path: '/quotation',
  redirect: '/quotation/list',
  component: Layout,
  children: [{
    path: 'list',
    component: () => import('@/views/quotation/list'),
    name: 'QuotationList',
    meta: {
      title: i18n.t('menu.quotation'),
      icon: 'el-icon-monitor'
    }
  }, {
    path: 'create',
    component: () => import('@/views/quotation/create'),
    name: 'QuotationCreate',
    hidden: true,
    meta: {
      title: i18n.t('menu.createquotation'),
      noCache: true,
      icon: 'el-icon-edit-outline',
      activeMenu:'/quotation/list'
    }
  }, {
    path: 'detail',
    hidden: true,
    component: () => import('@/views/quotation/detail'),
    name: 'QuotationPreviciew',
    meta: {
      noCache: true,
      title: i18n.t('menu.previciewquotation'),
      icon: 'el-icon-picture-outline-round',
      activeMenu: '/quotation/list'
    }
  }]
},
{
  path: '/company',
  component: Layout,
  redirect: '/company/index',
  name: 'company',
  meta: {
    title: i18n.t('menu.company'),
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'index',
    component: () => import('@/views/company/list'),
    name: 'CompanyList',
    meta: {
      title: i18n.t('menu.company'),
      icon: 'el-icon-office-building',
      activeMenu: '/company/index'
    }
  }, {
    path: 'create',
    hidden: true,
    component: () => import('@/views/company/create'),
    name: 'CompanyCreate',
    meta: {
      title: i18n.t('menu.createcompany'),
      icon: 'el-icon-monitor',
      activeMenu: '/company/index'
    }
  }, {
    path: 'detail',
    hidden: true,
    component: () => import('@/views/company/detail'),
    name: 'CompanyDetail',
    meta: {
      title: i18n.t('menu.companydetail'),
      icon: 'el-icon-monitor',
      activeMenu: '/company/index'
    }
  }]
},
{
  path: '/sms',
  component: Layout,
  redirect: '/sms/index',
  hidden: false,
  name: 'sms',
  meta: {
    title: i18n.t('menu.sms'),
    icon: 'el-icon-chat-dot-square'
  },
  children: [{
    path: 'index',
    component: () => import('@/views/sms/list'),
    name: 'SmsList',
    meta: {
      title: i18n.t('menu.sms'),
      icon: 'el-icon-chat-dot-square',
      activeMenu: '/sms/index'
    }
  }, {
    path: 'buy',
    component: () => import('@/views/sms/buy'),
    name: 'SmsBuy',
    hidden: true,
    meta: {
      title: i18n.t('menu.buysms'),
      icon: 'el-icon-chat-dot-square',
      activeMenu: '/sms/index'
    }
  }

  ]
},

{
  path: '/accountPassword',
  component: Layout,
  hidden: true,
  children: [{
    path: 'password',
    component: () => import('@/views/account/password'),
    name: 'AccountPassword',
    meta: {
      title: i18n.t('menu.updatepassword'),
      icon: 'el-icon-edit-outline'
    }
  }]
},
{
  path: '/setting',
  component: Layout,
  children: [{
    path: 'setting',
    component: () => import('@/views/profile/index'),
    name: 'Setting',
    meta: {
      title: i18n.t('menu.setting'),
      icon: 'el-icon-user'
    }
  }]
},
{
  path: '/pricing',
  component: Layout,
  redirect: '/pricing/index',
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/pricing/index'),
    name: 'pricingIndex',
    meta: {
      title: i18n.t('menu.joinvip'),
      icon: 'el-icon-trophy',
      noCache: true
    }
  }]
},
{
  path: '/review',
  component: Layout,
  redirect: '/review',
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/review/index'),
    name: 'pricingIndex',
    meta: {
      title: i18n.t('menu.'),
      icon: 'el-icon-chat-dot-round',
      noCache: true
    }
  }]
},
/** when your routing map is too long, you can split it into small modules **/
// componentsRouter,
// chartsRouter,
// nestedRouter,

// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
