export default {
  appname: '天天报价',
  notfound:'页面不存在',
  backhome:'返回首页',
  about:'关于我们',
  nodata:'暂无数据',
  loading:'正在加载',
  browser:'浏览器',
  docs:'使用文档',
  logout:'退出',
  solang:'助您开启更高效、智能的报价之旅！让您的报价单更具吸引力，提升与客户的沟通效果',
  success:'修改成功',
  back:'返回',
  menu:{
    home:'首 页',
    quotation:'报价管理',
    company:'公司管理',
    sms:'短信服务',
    setting:'个人设置',
    createquotation:'添加报价',
    previciewquotation:'预览报价单',
    createcompany:'添加公司',
    companydetail:'公司详情',
    buysms:'购买短信',
    updatepassword:'修改密码',
    joinvip:'成为会员',
    userevaluate:'用户评价',
  },
  login:{
    nameplaceholder:"请输入用户名",
    passwordplaceholder:"请输入密码",
    submit:'立即登录',
    register:'新用户注册',
    password_error:'密码错误',
    user_no_exist:'用户不存在'
  },
  dashboard:{
    welcome:'欢迎使用天天报价管理系统',
    instructions:'使用说明',
    feddback:'意见反馈/在线客服',
    roadmap:'路线图',
    changelog:'更新记录',
    guide:'上云指引',
    setp1:'设置公司/个人信息',
    setp2:'开始报价',
    source:'访问来源',
    arearank:'区域排行',
    pv:'访问量',
    pv_7:'最近7天',
    pv_15:'最近15天',
    pv_30:'最近30天',
    unknown_area:'未知地区',
    proportion:'占比'
  },
  person:{
    basesetting:'基础信息',
    nickname:'呢称',
    nickname_placeholder:'请输入呢称',
    email:'邮箱',
    email_placeholder:'请输入邮箱',
    mobile:'联系电话',
    mobile_placeholder:'请输入联系电话',
    avatar:'头像',
    upload_avatar:'上传头像',
    address:'联系地址',
    address_placeholder:'请输入联系地址',
    submit_text:'确定修改'
  },
  upload:{
    preview_title:'查看',
    upload_text:'上传图片',
    modal_title:'确定删除该图片?',
    modal_tips_text:'提示',
    modal_confirm_btn:'确定',
    modal_cancel_btn:'取消',
    limit:'`图片超限，最多可上传 {limit} 张图片`',
    size:'上传图片大小不能超过3M'
  },
  sms:{
    package_title:'套餐包余量',
    newuser:'新用户赠送 3 条短信',
    package_tips:'短信套餐包余量（条）',
    buy_btn_text:'购买',
    recorde_title:'发送记录',
    unknown_user:'未知用户',
    table_nickname:'客户呢称',
    table_phone:'客户手机',
    table_status:'发送状态',
    table_time:'发送事件',
    table_status_success:'成功',
    buy_package_title:'购买套餐包',
    buy_tips:'在线购买暂未开通，可以联系客服购买'
  },
  company:{
    tips:'当前版本只支持添加 1 个公司',
    create:'添加',
    table_title:'公司抬头',
    table_contact:'联系人',
    table_mobile:"联系电话",
    table_time:'添加事件',
    table_edit_btn:'编辑',
    table_delete_btn:'删除',
    table_action:'操作',
    modal_title:'确定删除该公司?',
    modal_tips_text:'提示',
    modal_confim_btn:'确定',
    modal_cancel_btn:'取消',
    modal_delete_success:'删除成功',
    modal_delete_cancel:'已取消删除',
    form_company:'公司名称',
    form_contact:'联系人',
    form_mobile:'手机',
    form_address:'地址',
    form_site:'网址',
    form_wechat:'微信',
    form_email:'邮箱',
    form_qq:'QQ',
    form_seal:'工章',
    form_seal_upload_tips:'上传工章',
    form_valid_company:'请输入公司名称',
    form_valid_contact:'请输入联系人',
    form_valid_address:'请输入公司地址',
    form_valid_mobile:'请输入手机号码',
    form_cancel_btn:'取消',
    form_comfig_btn:'确认保存',
    form_create_success:'添加成功',
    form_update_success:'修改成功',
    detail:'公司详情'
  },
  offer:{
    filter:{
      date:'日期范围',
      date_start:'开始日期',
      date_end:'结束日期',
      date_to:'至',
      customer:'客户',
      customer_placeholder:'请输入客户名称',
      search_btn:'查询'
    },
    form:{
      create:'新增报价',
      base_info:'基本信息',
      customer_name:'公司名称',
      valid_at_placeholder:'选择日期',
      customer_address:'公司地址',
      customer_contact:'联系人',
      customer_mobile:'手机号码',
      customer_email:'公司邮箱',
      date:'报价日期',
      date_placeholer:'选择日期',
      validate:'报价有效期',
      goods:{
        card_title:'商品信息',
        create_goods:'新增商品',
        name:'名称',
        model:'型号',
        num:'数量',
        unit_price:'单价',
        total_price:'总价',
        remark:'备注',
        action: '操作',
        action_view:'查看',
        action_edit:'编辑',
        action_delete:'移除',
        total_amount:'合计'
      },
      extent:{
        select_contact:'选择联系方式',
        company_title:'公司抬头',
        compmay_placeholder:'请选择公司抬头',
        seal:'加盖公章',
        seal_tips:'将会在报价单上显示公司的公章',
        remark:'备注信息',
        remark_placeholder:'请输入内容'
      },
      createok:{
        title:'报价单添加成功',
        send_sms_notification:'发送短信通知',
        back_offer_list:'返回报价列表'
      },
      cancel_btn:'取消',
      submit_btn:'提交',
      view_goods:'查看商品',
      good_rules:{
        name:'请输入商品名称',
        type:'请输入商品型号',
        num:'请输入商品数量',
        unit:'请选择单位',
        unit_price:'请输入单价',
        create_at:'请选择报价日期',
        company_id:'请选择公司抬头',
        customer_name:'请输入公司名称',
        customer_conatact:'请输入联系人',
        customer_phone:'请输入手机号码',
        customer_address:'请输入地址',
        valid_at:'请选择报价有效期',
        company_name:'请输入公司名称',
        company_address:'请输入公司地址',
        company_contact:'请输入联系人',
        company_mobile:'请输入手机号码'
      },
      other:{
        title:'添加商品',
        modal_text:'是否确定删除该商品?',
        modal_title:'提示',
        modal_ok:'确定',
        modal_cancel:'取消',
        delete_success:'删除成功',
        create_success:'添加成功',
        update_success:'修改成功',
        sms_send_success:'发送成功'

      }
    },
    table:{
      customer:'客户信息',
      validate:'报价有效期',
      create_time:'报价日期',
      pv:'浏览量',
      share_status:{
        title:'分享状态',
        options:['分享中','未分享']
      },
      sms_num:'已发短信数',
      action:{
       title:'操作',
       share:'分享',
       send_sms:'发短信',
       detail:'详情' 
      }
    },
    sendsms:{
      no_mobile_error:'未填写客户手机',
      modal_title:'确定发送短息将消耗一条短信额度?',
      modal_tips:'提示',
      submit_btn:'确定',
      cancel_btn:'取消',
      send_success:'发送成功',
      send_faild:'发送失败!',
      send_cancel:'取消发送',
    },
    pvdialog:{
      title:'浏览记录',
      index:'序号',
      address:'所在地',
      date:'浏览日期',
      ip:'IP',
      browser:'浏览器'
    },
    sharedialog:{
      title:'分享设置',
      tips:'关闭分享: 关闭开关或修改查看密码',
      status:'分享状态',
      link:'查看链接',
      password:'查看密码',
      refresh:'换一个',
      btn:'复制链接及查看密码',
      open_share_success:'开启分享成功',
      close_share_success:'关闭分享成功',
      updatepassword:'查看密码修改成功',
      copy:['链接','查看密码','一个免费制作报价单的网站']
    },
    detail:{
      title:'报价详情',
      customer_name:'客户名称',
      customer_address:'公司地址',
      customer_contact:'联系人',
      customer_mobile:'联系方式',
      customer_email:'公司邮箱',
      no:'报价单序号',
      date:'报价日期',
      validate:'报价有效期',
      goods_card_title:'商品信息',
      goods:{
        index:'序号',
        name:'商品名称',
        model:'型号及规格',
        num_unit:'数量/单位',
        unit_price:'单价',
        total_price:'总价',
        remark:'备注'
      },
      editgood:{
        name:'名称',
        type:'型号',
        num:'数量',
        unit_price:'单价',
        unit:'单位',
        unit_placeholder:'请选择单位',
        images:'商品图片',
        upload_images_text:'上传商品图片',
        video:'视频',
        upload_video_text:'上传视频',
        remark:'备注',
        submit:'提交',
        cancel:'取消',
        rules:'请输入',
        create_success:'添加成功',
        update_success:'修改成功'
      },
      company_title:'报价单位',
      company_website:'网址',
      company_contact:'联系人',
      company_mobile:'联系方式',
      company_wechat:'微信',
      company_seal:'单位公章',
      company_address:'地址',
      company_email:'邮箱',
      company_qq:'QQ',
      total_price:'合计',
      edit_btn:'编辑',
      print_btn:'打印报价单',
      delete_btn:'删除',
      comfirm_delete:'确定删除?',
      delete_success:'删除成功',
      customer_info:'客户信息'
    }
  },
  share:{
    view_password:'请输入查看密码',
    view_btn:'查看报价',
    password_error:'密码错误',
    quotation_closed: '该报价单已经被取消了，下次要早点哟。',
    goapp:"前往APP"
    
  },
  footer:{
    home:'首页',
    info:'使用说明',
    feedback:'意见反馈',
    roadmap:'路线图'
  },
 
  register:{
    input_name:'请输入用户名',
    input_password:'请输入密码',
    login:'立即注册',
    gologin:'去登录',
    success:'注册成功',
    exist:'已存在的用户名'
  }
}



// 10001 查看密码错误
// 10002 已存在的用户名
// 10003 用户名不存在
// 10004 用户名或者密码错误
// 10005 修改失败 - 密码
// 1006  修改失败 - 基本信息