<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo"> -->
        <div
          style="
            display: flex;
            align-items: center;
            padding-top: 4px;
            justify-content: center;
          "
        >
          <svg-icon v-if="logo" icon-class="logo" class="logo-svg" />
          <h1 v-else class="sidebar-title">{{ $t('appname') }}</h1>
        </div>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <div
          style="display: flex; align-items: center; padding: 10px 0 10px 14px"
        >
          <svg-icon v-if="logo" icon-class="logo" class="logo-svg-expand" />
          <h1 class="sidebar-title" style="margin-left: 5px">{{ $t('appname') }}</h1>
        </div>
      </router-link>
    </transition>
  </div>
</template>

<script>
const defaultSettings = require('@/settings.js')

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: defaultSettings.title,
      logo: require('@/assets/logo.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #fff;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 10px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #303133;
      font-weight: 500;
      line-height: 50px;
      font-size: 28px;
      font-family: 'alimama', Avenir, Helvetica Neue, Arial, Helvetica,
        sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    height: 50px;
    line-height: 50px;
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
.logo-svg {
  font-size: 34px;
  color: #1890ff;
  color: #165dff;
  margin: 0 auto;
  margin-top: 5px;
}
.logo-svg-expand {
  font-size: 40px;
  color: #1890ff;
  color: #165dff;
}
.svg-icon {
  margin-right: 8px !important;
}
</style>
