<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
.sign-task-status-round {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 5px;
}
.el-message{
   min-width: 100px!important;
   margin-top: 50px;
}
label{
  font-weight: normal;
  font-size: 14px;
}
.el-card{
  border: none;
  border-radius: 2px;
}
/* @font-face {
  font-family: "alimama";font-weight: 700;src: url("//at.alicdn.com/wf/webfont/jgGjJ3ckKOwU/WY8KafIXJ2I6.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/jgGjJ3ckKOwU/97I0nqoh2oKV.woff") format("woff");
  font-display: swap;
} */

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "alimama";src: url("//at.alicdn.com/wf/webfont/jgGjJ3ckKOwU/pjsM58NSnQiK.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/jgGjJ3ckKOwU/2wjnqHgo6wMN.woff") format("woff");
  font-display: swap;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.form-footer {
  width: 100%;
  height: 60px;
  background: #fff;
  text-align: right;
  padding-right: 40px;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 1px solid #eee;
  padding-top: 12px;
  z-index: 999
}
</style>

